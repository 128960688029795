import {useMutation, useQuery} from '@apollo/client';
import {Form, Select, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_USER_KYC_STATUS} from 'graphql/mutations/users';
import {GET_KYC_FIELDS} from 'graphql/queries/users';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './updateKyc.module.scss';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const UpdateKyc = () => {
  const [form] = Form.useForm();
  const {id} = useParams();
  const {Option} = Select;

  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Users),
  );

  const {data, loading, refetch} = useQuery(GET_KYC_FIELDS, {
    variables: {
      getUserByIdId: id,
      userId: id,
    },
  });
  const [updateKycStatus, {loading: loadingUpdate}] = useMutation(
    UPDATE_USER_KYC_STATUS,
  );

  const status = [
    {label: 'Not Started', value: 'NOT_STARTED'},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Pending Verification', value: 'PENDING_VERIFICATION'},
    {label: 'Approved', value: 'APPROVED'},
    {label: 'Rejected', value: 'REJECTED'},
  ];

  useEffect(() => {
    form.setFieldsValue({
      kycStatus:
        data?.getUserById?.user?.investorProfile?.identityCard?.kycStatus,
      amlStatus:
        data?.getUserById?.user?.investorProfile?.identityCard?.amlStatus,
    });
  }, [data?.getUserById?.user?.investorProfile]);

  const handleUpdateKycStatus = values => {
    updateKycStatus({
      variables: {
        userId: id,
        kycStatus: values?.kycStatus,
        amlStatus: values?.amlStatus,
      },
    })
      .then(({data: {updateKycStatus}}) => {
        if (updateKycStatus.__typename === 'Error') {
          toast.error('Something went wrong!');
        } else {
          toast.success('Updated Successfully');
          refetch();
        }
      })
      .catch(() => toast.error('Something went wrong!'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <h1>User KYC and AML Status</h1>
      </div>
      <div></div>
      <div className={styles.form}>
        <Form form={form} layout="vertical" onFinish={handleUpdateKycStatus}>
          <Form.Item
            rules={[{required: true, message: 'This is a required field'}]}
            className={styles.width}
            name="kycStatus"
            label="KYC Status">
            <Select>
              {status.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.width}
            name="amlStatus"
            label="AML Status">
            <Select>
              {status.map(data => (
                <Option key={data.value}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <SubmitButton
            disabled={!pageOperations?.update || loadingUpdate}
            label={'Update Status'}
          />
        </Form>
      </div>
    </div>
  );
};

export default UpdateKyc;
