import {Form, Input, Spin, Switch} from 'antd';
import styles from './mobileSetting.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {Button} from '@dabafinance/react-components';
import {useMutation, useQuery} from '@apollo/client';
import {TRANSLATE_TEXT} from 'graphql/mutations/notifications';
import {toast} from 'react-toastify';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {useEffect, useState} from 'react';
import {
  GET_MAINTENANCE_MODE,
  UPDATE_MAINTENANCE_MODE,
} from 'graphql/queries/mobileSettings';

export const MaintenanceMode = () => {
  const [openFinishModal, setOpenFinishModal] = useState(false);
  const [form] = Form.useForm();

  const [translateText, {loading: loadingTranslation}] =
    useMutation(TRANSLATE_TEXT);
  const [setMaintenanceMode, {loading: loadingUpdate}] = useMutation(
    UPDATE_MAINTENANCE_MODE,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    },
  );

  const {data, refetch, loading} = useQuery(GET_MAINTENANCE_MODE, {
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!data?.getMaintenanceMode?.data) {
      form.setFieldsValue({
        mode: false,
      });
    } else {
      form.setFieldsValue({
        mode: true,
        enMessage: data?.getMaintenanceMode?.data?.EN?.message,
        frMessage: data?.getMaintenanceMode?.data?.FR?.message,
      });
    }
  }, [data?.getMaintenanceMode?.data]);

  const handleTranslation = () => {
    let sourceText = form.getFieldValue('enMessage');

    if (!sourceText) return;

    translateText({
      variables: {
        sourceLang: 'EN',
        targetLang: 'FR',
        sourceText,
      },
    })
      .then(({data: {translateText}}) => {
        if (
          translateText.statusCode === 400 ||
          translateText.__typename === 'Error'
        ) {
          toast.error('Error Translating Text', translateText.message);
        } else {
          toast.success('Translated Successfully');
          form.setFieldValue('frMessage', translateText?.targetText);
        }
      })
      .catch(err => toast.error(err));
  };

  if (loading) {
    return (
      <div className="query-loading">
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.body}>
      <div className={styles.mHeader}>
        <h2>Maintenance Mode</h2>
        <p>
          This setting will enable maintenance mode for the application,
          rendering it unavailable to end-users while allowing administrators to
          perform necessary updates and configurations.
        </p>
      </div>
      <Form
        onFinish={() => setOpenFinishModal(true)}
        form={form}
        layout="vertical">
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          name="mode"
          className={styles.proField}
          label="Enable Maintenance Mode">
          <Switch
            defaultChecked={data?.getMaintenanceMode?.data ? true : false}
          />
        </Form.Item>
        <div className={styles.translateField}>
          <Form.Item
            name="enMessage"
            rules={[
              {
                required: form.getFieldValue('mode'),
                message: 'This is a Required Field',
              },
            ]}
            className={styles.proField}
            label="English Message">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Button
            type="secondary"
            disabled={loadingTranslation}
            label="Translate"
            onClick={handleTranslation}
          />
        </div>
        <Form.Item
          name="frMessage"
          className={styles.proField}
          rules={[
            {
              required: form.getFieldValue('mode'),
              message: 'This is a Required Field',
            },
          ]}
          label="French Message">
          <Input.TextArea rows={4} />
        </Form.Item>
        <SubmitButton label="Proceed to Update" />
      </Form>
      <ConfirmModal
        openModal={openFinishModal}
        setOpenModal={() => setOpenFinishModal(false)}
        confirmText="Are you sure you want to make this Update?"
        title="Confirm Update"
        loading={loadingUpdate}
        handleOperation={() =>
          setMaintenanceMode({
            variables: {
              data: {
                enabled: form.getFieldValue('mode') || false,
                message: {
                  'EN': {
                    status: !form.getFieldValue('mode'),
                    message: form.getFieldValue('enMessage'),
                  },
                  'FR': {
                    status: !form.getFieldValue('mode'),
                    message: form.getFieldValue('frMessage'),
                  },
                },
              },
            },
          })
            .then(({data: {setMaintenanceMode}}) => {
              if (
                setMaintenanceMode.statusCode === 400 ||
                setMaintenanceMode.__typename === 'Error'
              ) {
                toast.error(`Something went wrong`, setMaintenanceMode.message);
              } else {
                toast.success(setMaintenanceMode.message);
                setOpenFinishModal(false);
                refetch();
              }
            })
            .catch(error => toast.error(error))
        }
      />
    </div>
  );
};
