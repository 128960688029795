import {useEffect, useState} from 'react';
import styles from '../new/createGroup.module.scss';
import backIcon from 'images/back.png';
import UploadImage from 'components/Startup/profile/companyImage';
import {Form, Input, InputNumber, Select, Spin, Switch} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_TOPICS} from 'graphql/queries/investorUpdates';
import defualtImage from 'images/cameroon_full.png';
import {GET_ALL_GROUPS, GET_GROUP_BY_ID} from 'graphql/queries/groups';
import {UPDATE_GROUP} from 'graphql/mutations/groups';
import {toast} from 'react-toastify';
import AddUsersToGroup from '../addUsers/AddUsersToGroup';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const EditGroup = () => {
  const [imgUrl, setImgUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState(defualtImage);
  const [activeTab, setActiveTab] = useState('DETAILS');
  const [admin, setAdmin] = useState([]);
  const [interests, setInterests] = useState([]);
  const [form] = Form.useForm();
  const [allowDocumentUpload, setAllowDocumentUpload] = useState({
    admins: false,
    nonAdmins: false,
  });
  const [allowDownload, setAllowDownload] = useState({
    admins: false,
    nonAdmins: false,
  });
  const [allowScreenRecord, setAllowScreenRecord] = useState({
    admins: false,
    nonAdmins: false,
  });
  const navigate = useNavigate();

  const {Option} = Select;
  const {TextArea} = Input;
  const width = 732;

  const {id} = useParams();
  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Groups),
  );

  const {data, loading: loadingTopics} = useQuery(GET_ALL_TOPICS);
  const {
    loading,
    refetch,
    data: getGroup,
  } = useQuery(GET_GROUP_BY_ID, {
    variables: {
      groupId: id,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  const [updateGroup, {loading: loadingUpdate}] = useMutation(UPDATE_GROUP);

  useEffect(() => {
    if (getGroup?.getGroupById?.group) {
      setPreviewUrl(getGroup?.getGroupById?.group?.bannerImg);
      setAdmin(
        getGroup?.getGroupById?.group?.admins?.map(
          data =>
            `${data?.firstName};${data?.lastName};${data.id};${data?.email}`,
        ),
      );
      setImgUrl(getGroup?.getGroupById?.group?.imageUrl);
      setInterests(
        getGroup?.getGroupById?.group?.topics?.map(
          data => `${data.name};${data.id}`,
        ),
      );
      setAllowDocumentUpload(
        getGroup?.getGroupById?.group?.settings?.allowDocumentUpload,
      );
      setAllowDownload(getGroup?.getGroupById?.group?.settings?.allowDownload);
      setAllowScreenRecord(
        getGroup?.getGroupById?.group?.settings?.allowScreenRecord,
      );
      form.setFieldsValue({
        privacy: getGroup?.getGroupById?.group?.isPrivate
          ? 'PRIVATE'
          : 'PUBLIC',
        name: getGroup?.getGroupById?.group?.name,
        durationUnits:
          getGroup?.getGroupById?.group?.settings?.timeBasedMembership
            ?.defaultDuration?.unit,
        durationAmount:
          getGroup?.getGroupById?.group?.settings?.timeBasedMembership
            ?.defaultDuration?.value,
        durationEnabled:
          getGroup?.getGroupById?.group?.settings?.timeBasedMembership?.enabled,
        description: getGroup?.getGroupById?.group?.description,
        welcomeMessage: getGroup?.getGroupById?.group?.welcomeMessage,
        allowAdminSendMessage:
          getGroup?.getGroupById?.group?.settings?.allowSendMessages?.admins,
        allowUserSendMessage:
          getGroup?.getGroupById?.group?.settings?.allowSendMessages?.nonAdmins,
      });
    }
  }, [getGroup?.getGroupById?.group]);

  const handleUpdateGroup = values => {
    updateGroup({
      variables: {
        data: {
          groupId: id,
          name: values?.name,
          bannerImg: imgUrl,
          ...(values?.welcomeMessage && {
            welcomeMessage: values?.welcomeMessage,
          }),
          admins: admin.map(data => data.split(';')[2]),

          description: values?.description,
          isPrivate: values?.privacy === 'PRIVATE',
          topics: interests?.map(data => data?.split(';')[1]),
          settings: {
            timeBasedMembership: {
              enabled: values?.durationEnabled || false,
              defaultDuration: {
                unit: values?.durationUnits,
                value: values?.durationAmount,
              },
            },
            allowDocumentUpload: {
              admins: allowDocumentUpload?.admins,
              nonAdmins: allowDocumentUpload?.nonAdmins,
            },
            allowDownload: {
              admins: allowDownload?.admins,
              nonAdmins: allowDownload?.nonAdmins,
            },
            allowScreenRecord: {
              admins: allowScreenRecord?.admins,
              nonAdmins: allowScreenRecord?.nonAdmins,
            },
            allowSendMessages: {
              admins: values?.allowAdminSendMessage,
              nonAdmins: values?.allowUserSendMessage,
            },
          },
        },
      },
      refetchQueries: [{query: GET_ALL_GROUPS}],
    }).then(({data: {updateGroup}}) => {
      if (updateGroup.__typename === 'Error') {
        toast.error(updateGroup.message);
      } else {
        toast.success('Circle Updated Successfully');
        refetch();
        // navigate('/dashboard/groups');
      }
    });
  };

  if (loading || loadingTopics) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1 className={styles['h-style']}>Circles / </h1>
        <span>Update Circle</span>
      </div>
      <div className={styles['details-toggler']}>
        <span
          className={activeTab === 'DETAILS' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('DETAILS')}>
          {' '}
          Details
        </span>
        <span
          className={activeTab === 'USERS' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('USERS')}>
          {' '}
          Members
        </span>
      </div>
      {activeTab === 'DETAILS' && (
        <div className={styles['main-content']}>
          <h1>Image</h1>
          <UploadImage
            previewUrl={previewUrl}
            setPreviewUrl={setPreviewUrl}
            setImgUrl={setImgUrl}
            bucket={'group-images-bucket'}
            large
          />
          <Form
            onFinish={handleUpdateGroup}
            className={styles.form}
            form={form}
            layout="vertical">
            <Form.Item
              rules={[{required: true, message: 'This Field is Required'}]}
              className={styles.width}
              name="name"
              label="Name">
              <Input style={{width}} placeholder="Name of group" />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'This Field is Required'}]}
              className={styles.width}
              name="privacy"
              label="Privacy">
              <Select
                defaultValue={
                  getGroup?.getGroupById?.group?.isPrivate
                    ? 'PRIVATE'
                    : 'PUBLIC'
                }
                style={{width}}>
                <Option value="PUBLIC">Public</Option>
                <Option value="PRIVATE">Private</Option>
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'This Field is Required'}]}
              className={styles.width}
              name="description"
              label="Description">
              <TextArea
                style={{width}}
                placeholder="Circle Description"
                rows={4}
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'This Field is Required'}]}
              className={styles.width}
              name="welcomeMessage"
              label="Welcome Message">
              <TextArea
                style={{width}}
                placeholder="Circle Welcome Message"
                rows={4}
              />
            </Form.Item>
            <Form.Item name="interests" label="Interests">
              <Select
                onChange={e => setInterests(e)}
                style={{width}}
                value={interests}
                placeholder="Select Interests"
                defaultValue={getGroup?.getGroupById?.group?.topics?.map(
                  data => `${data.name};${data.id}`,
                )}
                mode="multiple">
                {data?.getAllTopics?.map(({name, icon, id}) => (
                  <Option key={`${name};${id}`}>
                    <div className={styles['all-data']}>
                      <img src={icon} alt="" />
                      <span>{name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.width}
              label="Search and Select Admin"
              name="admin">
              <UserSelectField
                defaultUsers={getGroup?.getGroupById?.group?.admins?.map(
                  data => data?.id,
                )}
                users={admin}
                setUsers={setAdmin}
              />
            </Form.Item>
            <div>
              <h1 className={styles['header-text']}>Circle Settings</h1>
            </div>
            <div>
              <h3 className={styles['header-subtext']}>
                Allow Document Upload
              </h3>
            </div>
            <div className={styles['settings-grid']}>
              <Form.Item
                name="allowUploadAdmins"
                label="Admins"
                valuePropName="checked">
                <Switch
                  defaultChecked={
                    getGroup?.getGroupById?.group?.settings?.allowDocumentUpload
                      ?.admins
                  }
                  checked={allowDocumentUpload?.admins}
                  onChange={e =>
                    setAllowDocumentUpload({...allowDocumentUpload, admins: e})
                  }
                />
              </Form.Item>
              <Form.Item
                name="allowUploadNonAdmins"
                label="Members"
                valuePropName="checked">
                <Switch
                  defaultChecked={
                    getGroup?.getGroupById?.group?.settings?.allowDocumentUpload
                      ?.nonAdmins
                  }
                  checked={allowDocumentUpload?.nonAdmins}
                  onChange={e =>
                    setAllowDocumentUpload({
                      ...allowDocumentUpload,
                      nonAdmins: e,
                    })
                  }
                />
              </Form.Item>
            </div>
            <div>
              <h3 className={styles['header-subtext']}>Allow Downloads</h3>
            </div>
            <div className={styles['settings-grid']}>
              <Form.Item
                name="allowDownloadAdmins"
                label="Admins"
                valuePropName="checked">
                <Switch
                  defaultChecked={
                    getGroup?.getGroupById?.group?.settings?.allowDownload
                      ?.admins
                  }
                  checked={allowDownload?.admins}
                  onChange={e =>
                    setAllowDownload({...allowDownload, admins: e})
                  }
                />
              </Form.Item>
              <Form.Item
                name="allowDownloadNonAdmins"
                label="Members"
                valuePropName="checked">
                <Switch
                  defaultChecked={
                    getGroup?.getGroupById?.group?.settings?.allowDownload
                      ?.nonAdmins
                  }
                  checked={allowDownload?.nonAdmins}
                  onChange={e =>
                    setAllowDownload({...allowDownload, nonAdmins: e})
                  }
                />
              </Form.Item>
            </div>
            <div>
              <h3 className={styles['header-subtext']}>Allow Screen Record</h3>
            </div>
            <div className={styles['settings-grid']}>
              <Form.Item
                name="allowRecordAdmins"
                label="Admins"
                valuePropName="checked">
                <Switch
                  defaultChecked={
                    getGroup?.getGroupById?.group?.settings?.allowScreenRecord
                      ?.admins
                  }
                  checked={allowScreenRecord?.admins}
                  onChange={e =>
                    setAllowScreenRecord({...allowScreenRecord, admins: e})
                  }
                />
              </Form.Item>
              <Form.Item
                name="allowRecordNonAdmins"
                label="Members"
                valuePropName="checked">
                <Switch
                  defaultChecked={
                    getGroup?.getGroupById?.group?.settings?.allowScreenRecord
                      ?.nonAdmins
                  }
                  checked={allowScreenRecord?.nonAdmins}
                  onChange={e =>
                    setAllowScreenRecord({...allowScreenRecord, nonAdmins: e})
                  }
                />
              </Form.Item>
            </div>
            <div>
              <h3 className={styles['header-subtext']}>Allow Send Messages</h3>
            </div>
            <div className={styles['settings-grid']}>
              <Form.Item
                name="allowAdminSendMessage"
                label="Admins"
                valuePropName="checked">
                <Switch
                  defaultChecked={
                    getGroup?.getGroupById?.group?.settings?.allowSendMessages
                      ?.admins
                  }
                />
              </Form.Item>
              <Form.Item
                name="allowUserSendMessage"
                label="Members"
                valuePropName="checked">
                <Switch
                  defaultChecked={
                    getGroup?.getGroupById?.group?.settings?.allowSendMessages
                      ?.nonAdmins
                  }
                />
              </Form.Item>
            </div>
            <h2>Time Based Membership</h2>
            <Form.Item name="durationEnabled" label="Enabled">
              <Switch
                defaultChecked={
                  getGroup?.getGroupById?.group?.settings?.timeBasedMembership
                    ?.enabled
                }
              />
            </Form.Item>
            <Form.Item
              name="durationUnits"
              className={styles.smallWidth}
              label="Duration Interval">
              <Select>
                <Option value="days">Days</Option>
                <Option value="months">Months</Option>
                <Option value="years">Years</Option>
              </Select>
            </Form.Item>
            <Form.Item name="durationAmount" label="Duration Units">
              <InputNumber className={styles['input-number']} min={1} />
            </Form.Item>

            <SubmitButton
              label="Update Circle"
              disabled={loadingUpdate || !tabOperations?.update}
            />
          </Form>
        </div>
      )}
      {activeTab === 'USERS' && (
        <div className={styles['main-content']}>
          <AddUsersToGroup operations={tabOperations} />
        </div>
      )}
    </div>
  );
};

export default EditGroup;
