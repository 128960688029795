import {useQuery} from '@apollo/client';
import {FinancialCurrencyCard} from './FinancialCards';
import {GET_ORDER_METRICS} from 'graphql/queries/financial';
import {DatePicker, Form, Switch} from 'antd';
import {useState} from 'react';
import styles from './financial.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import moment from 'moment';
import {ExportFinancialButton} from './ExportFinancialButton';
import {currentDayOfMonth, firstDayOfMonth} from './WalletMetrics';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

export const OrderMetrics = () => {
  const [form] = Form.useForm();
  const startDate = Form.useWatch('startDate', form);
  const endDate = Form.useWatch('endDate', form);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Financials),
  );

  const [data, setData] = useState({});
  const {loading, refetch} = useQuery(GET_ORDER_METRICS, {
    variables: {
      filters: {
        date: {
          range: 'MONTHLY',
        },
      },
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => setData(data?.getOrderMetrics?.data),
  });

  return (
    <>
      <div className={styles.filters}>
        {tabOperations?.export && (
          <ExportFinancialButton
            startDate={startDate}
            endDate={endDate}
            query={GET_ORDER_METRICS}
            type="Order"
          />
        )}

        <Form
          layout="horizontal"
          form={form}
          initialValues={{
            startDate: firstDayOfMonth,
            endDate: currentDayOfMonth,
          }}
          className={styles.form}
          onFinish={values => {
            if (values?.allTime) {
              return refetch({
                'filters': {
                  'date': {
                    'range': 'MONTHLY',
                  },
                  'allTime': true,
                },
              });
            }
            if (!values?.startDate && !values?.endDate) {
              return refetch({
                filters: {
                  date: {
                    range: 'MONTHLY',
                  },
                },
              });
            }
            refetch({
              filters: {
                date: {
                  ...(values?.startDate && {
                    startDate: moment(values?.startDate).format(),
                  }),
                  ...(values?.endDate && {
                    endDate: moment(values?.endDate).format(),
                  }),
                },
              },
            });
          }}>
          <Form.Item name="startDate">
            <DatePicker placeholder="Start Date" showTime allowClear />
          </Form.Item>
          <Form.Item name="endDate">
            <DatePicker placeholder="End Date" showTime allowClear />
          </Form.Item>
          <Form.Item name="allTime" label="All Time">
            <Switch />
          </Form.Item>
          <SubmitButton smallWidth label="Filter" />
        </Form>
      </div>
      <div className={styles.cardsRoot}>
        <h1>Total Number of Orders (Across Asset Class)</h1>
        <div>
          <h2>STOCKS</h2>
          <div className={styles.cards}>
            {loading
              ? Array(3)
                  .fill('')
                  .map((data, key) => (
                    <div key={key} className={styles.loadingSkeleton}>
                      {data}
                    </div>
                  ))
              : data?.stocks?.map((data, key) => (
                  <FinancialCurrencyCard
                    type={data?.currency}
                    key={key}
                    currency={data?.type}
                    amount={data?.total}
                  />
                ))}
          </div>
        </div>
        <div>
          <h2>BONDS</h2>
          <div className={styles.cards}>
            {loading
              ? Array(3)
                  .fill('')
                  .map((data, key) => (
                    <div key={key} className={styles.loadingSkeleton}>
                      {data}
                    </div>
                  ))
              : data?.bonds?.map((data, key) => (
                  <FinancialCurrencyCard
                    type={data?.currency}
                    key={key}
                    currency={data?.type}
                    amount={data?.total}
                  />
                ))}
          </div>
          {!!data?.others?.length && (
            <div>
              <h2>OTHER ASSETS</h2>
              <div className={styles.cards}>
                {loading
                  ? Array(3)
                      .fill('')
                      .map((data, key) => (
                        <div key={key} className={styles.loadingSkeleton}>
                          {data}
                        </div>
                      ))
                  : data?.others?.map((data, key) => (
                      <FinancialCurrencyCard
                        type={data?.currency}
                        key={key}
                        currency={data?.type}
                        amount={data?.total}
                      />
                    ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
