import {useQuery} from '@apollo/client';
import {FinancialCurrencyCard} from './FinancialCards';
import {GET_WALLET_METRICS} from 'graphql/queries/financial';
import {useState} from 'react';
import styles from './financial.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import moment from 'moment';
import {DatePicker, Form, Switch} from 'antd';
import {ExportFinancialButton} from './ExportFinancialButton';
import {NavTabsEnum} from 'utils/constants';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

export const firstDayOfMonth = moment().startOf('month');
export const currentDayOfMonth = moment();

export const WalletMetrics = () => {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const startDate = Form.useWatch('startDate', form);
  const endDate = Form.useWatch('endDate', form);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Financials),
  );

  const {loading, refetch} = useQuery(GET_WALLET_METRICS, {
    variables: {
      filters: {
        date: {
          range: 'MONTHLY',
        },
      },
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => setData(data?.getWalletMetrics?.data),
  });

  return (
    <>
      <div className={styles.filters}>
        {tabOperations?.export && (
          <ExportFinancialButton
            startDate={startDate}
            endDate={endDate}
            query={GET_WALLET_METRICS}
            type="Wallet"
          />
        )}
        <Form
          layout="horizontal"
          form={form}
          initialValues={{
            startDate: firstDayOfMonth,
            endDate: currentDayOfMonth,
          }}
          className={styles.form}
          onFinish={values => {
            if (values?.allTime) {
              return refetch({
                'filters': {
                  'date': {
                    'range': 'MONTHLY',
                  },
                  'allTime': true,
                },
              });
            }
            if (!values?.startDate && !values?.endDate) {
              return refetch({
                filters: {
                  date: {
                    range: 'MONTHLY',
                  },
                },
              });
            }
            refetch({
              filters: {
                date: {
                  ...(values?.startDate && {
                    startDate: moment(values?.startDate).format(),
                  }),
                  ...(values?.endDate && {
                    endDate: moment(values?.endDate).format(),
                  }),
                },
              },
            });
          }}>
          <Form.Item name="startDate">
            <DatePicker placeholder="Start Date" showTime allowClear />
          </Form.Item>
          <Form.Item name="endDate">
            <DatePicker placeholder="End Date" showTime allowClear />
          </Form.Item>
          <Form.Item name="allTime" label="All Time">
            <Switch />
          </Form.Item>
          <SubmitButton smallWidth label="Filter" />
        </Form>
      </div>
      <div className={styles.cardsRoot}>
        <div>
          <h2>Total Wallet Revenue (Deposit + Withdrawals + Swap )</h2>
          <div className={styles.cards}>
            {loading
              ? Array(3)
                  .fill('')
                  .map((data, key) => (
                    <div key={key} className={styles.loadingSkeleton}>
                      {data}
                    </div>
                  ))
              : data?.totalRevenue?.map((data, key) => (
                  <FinancialCurrencyCard
                    type={data?.currency}
                    key={key}
                    currency={data?.currency}
                    symbol={data?.symbol}
                    amount={data?.amount}
                  />
                ))}
          </div>
        </div>
        <div>
          <h2>Gross Deposit Fees (This is the fee generated from Deposits)</h2>
          <div className={styles.cards}>
            {loading
              ? Array(3)
                  .fill('')
                  .map((data, key) => (
                    <div key={key} className={styles.loadingSkeleton}>
                      {data}
                    </div>
                  ))
              : data?.grossDepositFees?.map((data, key) => (
                  <FinancialCurrencyCard
                    type={data?.currency}
                    key={key}
                    currency={data?.currency}
                    symbol={data?.symbol}
                    amount={data?.amount}
                  />
                ))}
          </div>
          <div>
            <h2>
              Gross Withdrawals Fees (This is the fee generated from
              Withdrawals)
            </h2>
            <div className={styles.cards}>
              {loading
                ? Array(3)
                    .fill('')
                    .map((data, key) => (
                      <div key={key} className={styles.loadingSkeleton}>
                        {data}
                      </div>
                    ))
                : data?.grossWithdrawalFees?.map((data, key) => (
                    <FinancialCurrencyCard
                      type={data?.currency}
                      key={key}
                      currency={data?.currency}
                      symbol={data?.symbol}
                      amount={data?.amount}
                    />
                  ))}
            </div>
          </div>
          <div>
            <h2>Gross Swap Fees</h2>
            <div className={styles.cards}>
              {loading
                ? Array(3)
                    .fill('')
                    .map((data, key) => (
                      <div key={key} className={styles.loadingSkeleton}>
                        {data}
                      </div>
                    ))
                : data?.grossSwapFees?.map((data, key) => (
                    <FinancialCurrencyCard
                      type={data?.currency}
                      key={key}
                      currency={data?.currency}
                      symbol={data?.symbol}
                      amount={data?.amount}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
