import {gql} from '@apollo/client';

export const GET_ALL_WALLET_PAYMENT_PROVIDERS = gql`
  query GetAllPaymentProviders {
    getAllPaymentProviders {
      ... on Error {
        message
        statusCode
        type
        errorTypeEnum
        retryAfter
      }
      ... on PaymentProviderList {
        providers {
          id
          enabled
          provider
          currencies
        }
      }
    }
    getPlatformSupportedCurrenciesForAdmin {
      ... on Error {
        message
        statusCode
      }
      ... on PlatformSupportedCurrencyList {
        currencies {
          enabled
          currency
          currencySymbol
          swap {
            sourceCurrencyEnabledForSwap
            minSwapAmountInSourceCurrency
            maxSwapAmountInSourceCurrency
          }
          currencyName
          deposit {
            enabledPaymentMethods
          }
          withdrawal {
            enabledPaymentMethods
          }
        }
      }
    }
    getMobileMoneyOperatorsForAdmin {
      ... on Error {
        message
        statusCode
      }
      ... on MobileMoneyOperatorsList {
        operators {
          countryName
          currencyCode
          logo
          supportedProviders {
            providerCode
          }
        }
      }
    }
  }
`;

export const GET_BOTH_PLAFORM_AND_WALLET_CURRENCY = gql`
  query GetPaymentProviderByProviderName(
    $providerName: PaymentGatewayProviderTypes!
    $currency: WalletCurrency
    $currency2: WalletCurrency!
  ) {
    getPaymentProviderByProviderName(
      providerName: $providerName
      currency: $currency
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on PaymentProviderSettings {
        currencies
        enabled
        provider
      }
    }
    getSupportedCurrencyByCurrencyName(currency: $currency2) {
      ... on Error {
        message
        statusCode
      }
      ... on WalletCurrencySettings {
        deposit {
          limit {
            daily
            monthly
            weekly
          }
        }
        withdrawal {
          limit {
            daily
            weekly
            monthly
          }
        }
      }
    }
  }
`;

export const GET_PLATFORM_WALLET_CURRENCY_BY_NAME = gql`
  query GetSupportedCurrencyByCurrencyName($currency: WalletCurrency!) {
    getSupportedCurrencyByCurrencyName(currency: $currency) {
      ... on Error {
        message
        statusCode
      }
      ... on WalletCurrencySettings {
        enabled
        currency
        currencySymbol
        currencyName
        displayCurrency {
          enabled
        }
        deposit {
          enabledPaymentMethods
          limit {
            daily
            monthly
            weekly
          }
          enabled
        }
        withdrawal {
          enabledPaymentMethods
          enabled
          limit {
            daily
            weekly
            monthly
          }
        }
      }
    }
  }
`;

export const GET_ALL_WALLET_TRANSACTIONS_FOR_ADMIN = gql`
  query GetAllTransactionsForAdmin($input: GetWalletTransactionInput!) {
    getAllTransactionsForAdmin(input: $input) {
      ... on Error {
        message
        statusCode
        type
        errorTypeEnum
        retryAfter
      }
      ... on WalletTransactionList {
        page
        totalDocs
        totalPages
        hasPrevPage
        hasNextPage
        prevPage
        nextPage

        transactions {
          createdAt
          currency
          direction
          id
          referenceId
          paymentMetadata
          paymentGateway
          paymentProvider
          type
          status
          totalTransactionAmountIncludingFees
          transactionAmountNetOfFees
          transactionFee
          updatedAt
          user {
            id
            firstName
            lastName
            username
          }
          order {
            totalFee
            type
            price
            direction
          }
          swap {
            calculatedFees
            amountSwapped
            amountReceived
            sourceCurrency {
              currency
            }
            targetCurrency {
              currency
            }
          }
        }
      }
    }
  }
`;

export const GET_MOBILE_MONEY_OPERATOR_BY_NAME = gql`
  query GetMobileMoneyOperatorByCountry(
    $countryName: MobileSupportedCountries!
  ) {
    getMobileMoneyOperatorByCountry(countryName: $countryName) {
      ... on Error {
        message
        statusCode
        type
        errorTypeEnum
        retryAfter
      }
      ... on MobileMoneyOperators {
        countryName
        currencyCode
        countryISO
        logo
        subText
        supportedProviders {
          providerName
          gateway
          isActive
          howToGuide {
            description
            imgURL
            videoURL
            link
          }
          direction
          feeSchedule {
            deposit {
              variableFee
              fixedFee
            }
            withdrawal {
              variableFee
              fixedFee
            }
          }
          countryCode
          feeSchedule {
            deposit {
              fixedFee
              variableFee
            }
            withdrawal {
              fixedFee
              variableFee
            }
          }
          providerLogo
          minWithdrawalAmount
          maxWithdrawalAmount
          minDepositAmount
          maxDepositAmount
          mobileMoneyDepositUSSDCode
          mobileMoneyDepositUSSDCodeDescription
          mobileMoneyWithdrawalUSSDCode
          mobileMoneyWithdrawalUSSDCodeDescription
          providerCode
        }
      }
    }
  }
`;

export const GET_GLOBAL_WALLET_PROVIDERS = gql`
  query GlobalPlatformWalletSettings {
    getGlobalPlatformSettings {
      ... on GlobalPlatformWalletSettings {
        isDepositAllowed
        isTransfersAllowed
        isWithdrawalAllowed
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const GET_WALLET_TRANSACTION_BY_ID = gql`
  query AdminGetWalletTransactionById(
    $transactionId: ID!
    $currency: WalletCurrency!
    $userId: ID!
  ) {
    adminGetWalletTransactionById(
      transactionId: $transactionId
      currency: $currency
      userId: $userId
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on WalletTransaction {
        id
        currency
        transactionAmountNetOfFees
        totalTransactionAmountIncludingFees
        transactionFee
        paymentGateway
        referenceId
        paymentGateway
        type
        status
        user {
          imageUrl
          firstName
          id
          username
          lastName
        }
        createdAt
        updatedAt
        order {
          totalFee
          type
          price
          direction
          assetCurrency
          symbol
        }
        swap {
          calculatedFees
          amountSwapped
          amountReceived
          amountPaid
          sourceCurrency {
            currency
            currencySymbol
          }
          targetCurrency {
            currency
            currencySymbol
          }
        }
      }
    }
  }
`;

export const GET_PAYMENT_PROVIDER_DETAILS_BY_ID = gql`
  query GetPaymentProviderByProviderName(
    $providerName: PaymentGatewayProviderTypes!
    $currency: WalletCurrency
  ) {
    getPaymentProviderByProviderName(
      providerName: $providerName
      currency: $currency
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on PaymentProviderSettings {
        currencies
        enabled
        provider
      }
    }
  }
`;

export const GET_MOBILE_MONEY_OPERATOR = gql`
  query GetMobileMoneyOperatorsForAdmin {
    getMobileMoneyOperatorsForAdmin {
      ... on Error {
        message
        statusCode
      }
      ... on MobileMoneyOperatorsList {
        operators {
          provider
          name
          code
          supportedCurrencies {
            name
          }
        }
      }
    }
  }
`;

// FX RATES

export const GET_ALL_FX_EXCHANGE_RATES = gql`
  query GetAllFxExchangeRates {
    getAllFxExchangeRates {
      ... on Error {
        message
        statusCode
      }
      ... on ExchangeRateList {
        rates {
          id
          enabled
          rate
          sourceCurrency
          targetCurrency
          shouldCheckMarketRate
          dailyThresholdForAutomaticSwapToTargetCurrency {
            initial
            remaining
            shouldNotifyAdminIfRemainingIsBelow
          }
        }
      }
    }
  }
`;

export const GET_TOTAL_USER_CASH_BALANCE = gql`
  query GetPlatformUserTotalCashBalance($input: GetUserTotalCashBalanceInput) {
    getPlatformUserTotalCashBalance(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on PlatformUserTotalCashBalance {
        currency {
          display
          source
        }
        totalBalanceInSourceCurrency
        totalBalanceInDisplayCurrency
        paymentProviderBalance
      }
    }
  }
`;

export const GET_PUBLIC_EXCHANGE_RATES = gql`
  query GetPublicFxExchangeRates(
    $from: WalletCurrency!
    $to: [WalletCurrency]
  ) {
    getPublicFxExchangeRates(from: $from, to: $to) {
      ... on Error {
        message
        statusCode
      }
      ... on PublicExchangeRate {
        from
        to {
          rate
          currency
        }
        lastUpdated
      }
    }
  }
`;

export const GET_FX_EXCHANGE_RATE_BY_ID = gql`
  query GetFxExchangeRateById($getFxExchangeRateByIdId: ID!) {
    getFxExchangeRateById(id: $getFxExchangeRateByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on ExchangeRate {
        enabled
        feeSchedule {
          mode
          fixedFee
          variableFee
        }
        id
        minSwapAmountInSourceCurrency
        maxSwapAmountInSourceCurrency
        rate
        shouldCheckMarketRate
        sourceCurrency
        targetCurrency
      }
    }
  }
`;

export const GET_ALL_SWAP_TRANSACTIONS = gql`
  query GetAllSwapTransactions($input: SwapFilters) {
    getAllSwapTransactions(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on AllSwapTransactionsList {
        totalSwapTransactions
        swapTransactions {
          sourceCurrency {
            currency
          }
          targetCurrency {
            currency
          }
          rate
          amountPaid
          amountSwapped
          amountReceived
          calculatedFees
          status
          createdAt
          id
          lockId
          referenceId
          user {
            imageUrl
            firstName
            lastName
          }
        }
        paginate {
          hasNextPage
          hasPrevPage
          total
        }
      }
    }
  }
`;

export const GENERATE_TRANSACTION_REPORT = gql`
  query GenerateTransactionReport($filters: TransactionReportFilters) {
    generateTransactionReport(filters: $filters) {
      ... on Error {
        message
        statusCode
      }
      ... on TransactionReport {
        responseMessage
      }
    }
  }
`;

export const GENERATE_CURRENCY_EXPOSURE = gql`
  query Error {
    generateCurrencyExposureReport {
      ... on Error {
        message
        statusCode
      }
      ... on TransactionReport {
        responseMessage
      }
    }
  }
`;

export const GENERATE_RECONCILIATION_REPORT = gql`
  query GenerateReconciliationReport($filters: TransactionReportFilters) {
    generateReconciliationReport(filters: $filters) {
      ... on Error {
        message
        statusCode
      }
      ... on TransactionReport {
        responseMessage
      }
    }
  }
`;

export const GENERATE_CUSTOMER_ACTIVITY = gql`
  query GenerateCustomerActivityReport($filters: TransactionReportFilters) {
    generateCustomerActivityReport(filters: $filters) {
      ... on Error {
        message
        statusCode
      }
      ... on TransactionReport {
        responseMessage
      }
    }
  }
`;

export const GENERATE_LIQUIDITY_REPORT = gql`
  query Error {
    generateLiquidityReport {
      ... on Error {
        message
        statusCode
      }
      ... on TransactionReport {
        responseMessage
      }
    }
  }
`;
